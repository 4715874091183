export const environment = {
  production: false,
  baseHref: '/',
  redirectUri: 'https://reporting-ui.prod.ag2goservices.amgreetings.com/',
  apiScopeUrl: 'api://ab1bb4cc-f385-4759-9c2c-b590387f128b/access_as_user',
  clientId: '00da5643-793c-4bc3-ae15-ea72585827f5',
  postLogoutRedirectUri: 'https://reporting-ui.prod.ag2goservices.amgreetings.com/',
  webApi: 'https://reporting-api.prod.ag2goservices.amgreetings.com/api',
  authApi: 'https://mobile-auth.prod.ag2goservices.amgreetings.com/api/auth/sign-in',
  userRolesAuthorization: true,
  tablePaginationSizes: [50, 200, 300, 500],
  pageableAllItems: 99999,
  noLoadingSpinnerHeader: 'loading-spinner-disabled',
  httpRequestChunkSize: 100
};
